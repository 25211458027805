import React from 'react';
import './Example18.css';

const Example18 = () => {
    return (
        <React.Fragment>

<div className="section-text">
The other direction declaration will modify the alignment vertically instead. You have to actively specify this on the parent element to enable it for it's children.
</div>

<pre>
    <code className="language-markup">
{`<!-- column example -->
<div class="parent">
    <div class="child">1</div>
    <div class="child">2</div>
    <div class="child">3</div>
</div>`}
    </code>
</pre>

            <div className="example-wrapper-column">

 <pre>
    <code className="language-css">
{`.parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}`}
    </code>
</pre>

                <div className="example-wrapper-inner flex18-wrapper">
                    <example-box>1</example-box>
                    <example-box>2</example-box>
                    <example-box>3</example-box>
                </div>
            </div>
            <div className="example-codepen"><a href="https://codepen.io/Grinspoon/pen/LaOyYo" target="_blank" rel="noopener noreferrer">Edit example on codepen</a>
                <i className="fab fa-codepen"></i>
            </div>
        </React.Fragment>
    )
}

export default Example18;