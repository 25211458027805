import React from 'react';
import './ExampleCSS3.css';

const ExampleCSS3 = () => {
    return (
<React.Fragment>

<div className="section-text">
Pseudo-elements will style a specific part of an element, this can be useful if you want to chain an element with another in the same scope. The selector uses double colon so it's easier separated from pseudo-classes which uses single.
</div>

<pre>
    <code className="language-markup">
{`<!-- ::before example -->
<div class="parent">
    <div class="child-1">text</div>
</div>`}
    </code>
</pre>

<div className="example-wrapper-column">

<pre>
    <code className="language-css">
{`.child-1::before {
    content:"::before pseudo element";
}`}
    </code>
</pre>

                        <div className="example-wrapper-inner css3-wrapper">
                            <example-box class="pseudo-child-1">text</example-box>
                        </div>

<pre>
    <code className="language-markup">
{`<!-- ::after example -->
<div class="parent">
    <div class="child-2">text</div>
</div>`}
    </code>
</pre>

<pre>
    <code className="language-css">
{`.child-2::after {
    content:"::after pseudo element";
}`}
    </code>
</pre>

                            <div className="example-wrapper-inner css3-wrapper">
                                <example-box class="pseudo-child-2">text</example-box>
                            </div>

 <pre>
    <code className="language-markup">
{`<!-- selection example -->
<div class="parent">
    <div class="child-3">select this text to see a different color</div>
</div>`}
    </code>
</pre>

<pre>
    <code className="language-css">
{`.child-3::selection {
    color: #fffdf2;
    background: #3ed9ff;
}`}
    </code>
</pre>

                            <div className="example-wrapper-inner css3-wrapper">
                            <example-box class="pseudo-child-3">select this text to see a different color</example-box>
                            </div>
                        </div>
                    <div className="example-codepen"><a href="https://codepen.io/Grinspoon/pen/QoQNEG" target="_blank" rel="noopener noreferrer">Edit example on codepen</a>
                        <i className="fab fa-codepen"></i>
                    </div>
                </React.Fragment>
    )
}

export default ExampleCSS3;