import React from 'react';
import Example1 from './Example1';
import Example2 from './Example2';
import Example3 from './Example3';
import Example4 from './Example4';
import Example5 from './Example5';
import Example6 from './Example6';
import Example7 from './Example7';
import Example8 from './Example8';
import Example9 from './Example9';
import Example10 from './Example10';
import Example11 from './Example11';
import Example12 from './Example12';
import Example13 from './Example13';
import Example14 from './Example14';
import Example15 from './Example15';
import Example16 from './Example16';
import Example17 from './Example17';
import Example18 from './Example18';
import Example19 from './Example19';
import Example20 from './Example20';
import Example21 from './Example21';

const Example = ({exampleName}) => {

    const getFlexExample = () => {
        switch (exampleName) {
            case 'example1':
                return <Example1 />;
            case 'example2':
                return <Example2 />;
            case 'example3':
                return <Example3 />;
            case 'example4':
                return <Example4 />;
            case 'example5':
                return <Example5 />;
            case 'example6':
                return <Example6 />;
            case 'example7':
                return <Example7 />;
            case 'example8':
                return <Example8 />;
            case 'example9':
                return <Example9 />;
            case 'example10':
                return <Example10 />;
            case 'example11':
                return <Example11 />;
            case 'example12':
                return <Example12 />;
            case 'example13':
                return <Example13 />;
            case 'example14':
                return <Example14 />;
            case 'example15':
                return <Example15 />;
            case 'example16':
                return <Example16 />;
            case 'example17':
                return <Example17 />;
            case 'example18':
                return <Example18 />;
            case 'example19':
                return <Example19 />;
            case 'example20':
                return <Example20 />;
            case 'example21':
                return <Example21 />;
            default:
                return null;
        }
    }

    return (
        <div className='example'>
            {getFlexExample()}
        </div>
    )
}

export default Example;