import React from 'react';
import './Example2.css';

const Example2 = () => {
    return (
        <React.Fragment>

<div className="section-text">
The flex declaration determent how much each child will occupy inside the parent container, this is one of the core declarations for when making a basic skeleton structure for a project. As you can see in the example below there are a number of different variations but the most common uses are flex: auto; flex: initial; flex: none; flex: 1;
</div>

<pre>
    <code className="language-markup">
{`<!-- flex declaration example -->
<div class="parent">
        <div class="child">
            <div class="child-1"></div>
            <div class="child-1"></div>
        </div>
        <div class="child">
            <div class="child-2"></div>
            <div class="child-3"></div>
            <div class="child-3"></div>
        </div>
        <div class="child">
            <div class="child-4"></div>
            <div class="child-5"></div>
        </div>
        <div class="child">
            <div class="child-6"></div>
            <div class="child-6"></div>
            <div class="child-7"></div>
        </div>
</div>`}
    </code>
</pre>

            <div className="example-wrapper-column">

<pre>
    <code className="language-css">
{`.child-1 {
    flex: 1;
}

.child-2 {
    flex: none;
}

.child-3 {
    flex: 2;
}

.child-4 {
    flex: auto;
}

.child-5 {
    flex: initial;
}

.child-6 {
    flex: 4;
}

.child-7 {
    flex: 1 50%;
}`}
    </code>
</pre>
          

                <div className="flex2-wrapper">
                    <div className="flex2-example1">
                        <example-box>child-1</example-box>
                        <example-box>child-1</example-box>
                    </div>
                    <div className="flex2-example2">
                        <example-box>child-2</example-box>
                        <example-box>child-3</example-box>
                        <example-box>child-3</example-box>
                    </div>
                    <div className="flex2-example3">
                        <example-box>child-4</example-box>
                        <example-box>child-5</example-box>
                    </div>
                    <div className="flex2-example4">
                        <example-box>child-6</example-box>
                        <example-box>child-6</example-box>
                        <example-box>child-7</example-box>
                    </div>
                </div>
            </div>
            <div className="example-codepen"><a href="https://codepen.io/Grinspoon/pen/MxOJxy" target="_blank" rel="noopener noreferrer">Edit example on codepen</a>
                <i className="fab fa-codepen"></i>
            </div>
        </React.Fragment>
    )
}

export default Example2;