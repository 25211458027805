
// _____________ PreventClick

function preventClick(time, element) {
    document.querySelectorAll(element).forEach((item) => {
        item.classList.add('prevent-click');
    });

    setTimeout(() => {
        document.querySelectorAll(element).forEach((item) => {
            item.classList.remove('prevent-click');
        });
    }, time);
}

// _____________ copyToClipboard

function copyToClipboard(target) {
    document.querySelectorAll(target).forEach((item) => {
        copyToClipboardMsg(document.querySelector('.' + item.className));
    });
}

function copyToClipboardMsg(elem) {
    const targetId = 'hiddenCopyText';
    const target = document.createElement('textarea');

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
        target.setAttribute('disabled', 'true');

        document.querySelectorAll('.section-copy-text').forEach((item) => {
            item.innerHTML = "Copied!";
        });
    }

    target.setAttribute('readonly', 'readonly');
    target.id = targetId;
    document.body.appendChild(target);
    target.textContent = elem.innerHTML;

    target.focus();
    target.setSelectionRange(0, target.value.length);

    let succeed;
    try {
        succeed = document.execCommand('copy');
    } catch (e) {
        succeed = false;
    }

    document.getElementById("hiddenCopyText").outerHTML = '';

    return succeed;
}

export {
    preventClick,
    copyToClipboard,
    copyToClipboardMsg
}