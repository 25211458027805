import React from "react";
import './Menu.css';
import { flexbox } from '../../sections-flex';
import { css } from '../../sections-css';
import { HashLink as Link } from 'react-router-hash-link';

class Menu extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      settings: 'menu-show',
      flexbox: 'menu-show',
      css: 'menu-show'
    }
  }

  componentDidMount = () => {
    let preventLinkDrag = document.querySelectorAll("a.menu-link");
    let menuLinks = preventLinkDrag.length;
 
    for (let i = 0; i < menuLinks; i++) {
      preventLinkDrag[i].draggable = false;
    }
  }

  menuToggle(type) {
    this.setState({ [type]: this.state[type] === 'menu-show' ? 'menu-hide' : 'menu-show' });
  }

  removeHash = () => {
    const isSafari = !!navigator.userAgent.match(/safari/i) && !navigator.userAgent.match(/chrome/i) && typeof document.body.style.webkitFilter !== "undefined" && !window.chrome;
    if (!isSafari) {
      window.history.replaceState(null, null, ' ');
    }
  }

  removeIntroduction = () => {
    const removeIndex = flexbox.slice();
    removeIndex.shift();
    return removeIndex;
  }

  render() {
    return (
    <React.Fragment>
        <div className="menu">
          <div className="menu-content">

            <div className={`menu-wrapper ${this.state.settings}`}>
              <div className="menu-title" onClick={() => this.menuToggle('settings')}><i className="fas fa-angle-down"></i> Settings</div>
              <div className="menu-links-wrapper">
                <div className="menu-link" onClick={this.props.onClick}>
                  <i className="fas fa-adjust"></i> dark theme: {this.props.theme}
                </div>
                <div className="menu-link" onClick={this.props.markupToggle}>
                  <i className="fas fa-align-left"></i> markup sections: {this.props.markupVisible}
                </div>
              </div>
            </div>

            <div className={`menu-wrapper ${this.state.flexbox}`}>
              <div className="menu-title" onClick={() => this.menuToggle('flexbox')}><i className="fas fa-angle-down"></i> Flexbox</div>
              <div className="menu-links-wrapper">
                {this.removeIntroduction().map((section, index) => {
                  return (
                    <Link
                      className="menu-link"
                      to={`#${index + 1}`}
                      key={index}
                      scroll={el => el.scrollIntoView({ behavior: 'auto', block: 'start' })}
                      onClick={this.removeHash()}
                    >
                      <i className="fas fa-link"></i> {section.name}
                    </Link>
                  )
                })}
              </div>
            </div>

            <div className={`menu-wrapper ${this.state.css}`}>
              <div className="menu-title" onClick={() => this.menuToggle('css')}><i className="fas fa-angle-down"></i> CSS</div>
              <div className="menu-links-wrapper">
                {css.map((section, index) => {
                  return (
                    <Link
                      className="menu-link"
                      to={`#${index + flexbox.length}`}
                      key={index}
                      scroll={el => el.scrollIntoView({ behavior: 'auto', block: 'start' })}
                      onClick={this.removeHash()}
                    >
                      <i className="fas fa-link"></i> {section.name}
                    </Link>
                  )
                })}
              </div>
            </div>

          </div>
          <div className="menu-spacer"></div>
        </div>
      </React.Fragment>
    );
  }
}

export default Menu;