import React from 'react';
import ExampleCSS1 from './ExampleCSS1';
import ExampleCSS2 from './ExampleCSS2';
import ExampleCSS3 from './ExampleCSS3';
import ExampleCSS4 from './ExampleCSS4';

const ExampleCSS = ({exampleName}) => {

    const getCSSExample = () => {
        switch (exampleName) {
            case 'example1':
                return <ExampleCSS1 />;
            case 'example2':
                return <ExampleCSS2 />;
            case 'example3':
                return <ExampleCSS3 />;
            case 'example4':
                return <ExampleCSS4 />;
            default:
                return null;
        }
    }

    return (
        <div className='example'>
            {getCSSExample()}
        </div>
    )
}

export default ExampleCSS;