import React from 'react';
import './Example6.css';

const Example6 = () => {
    return (
        <React.Fragment>

<div className="section-text">
If you want to align a child element in a specific position inside a parent element you have to use one or two flex declarations to achieve this. To place an element horizontally (left center right) inside the parent we use justify-content: flex-start/center/flex-end; To make this change vertically we use align-items: flex-start/center/flex-end; You can combine these as well, as you can see in the example listed.
</div>

<pre>
    <code className="language-markup">
{`<!-- element left top example -->
<div class="parent">
    <div class="child"></div>
</div>`}
    </code>
</pre>

            <div className="example-wrapper-column">

<pre>
    <code className="language-css">
{`.parent {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}`}
    </code>
</pre>

                <div className="example-wrapper-inner flex6-wrapper">
                    <example-box></example-box>
                </div>
            </div>
            <div className="example-codepen"><a href="https://codepen.io/Grinspoon/pen/BbmWQB" target="_blank" rel="noopener noreferrer">Edit example on codepen</a>
                <i className="fab fa-codepen"></i>
            </div>
        </React.Fragment>
    )
}

export default Example6;