import React from 'react';
import './Example15.css';

const Example15 = () => {
    return (
        <React.Fragment>

<div className="section-text">
How can we position child elements differently inside the same parent container? We use align-self, this declaration gives the child the ability to have it's on rule as long as the parent has initialised flexbox with display: flex;
</div>

<pre>
    <code className="language-markup">
{`<!-- align self -->
<div class="parent">
    <div class="child-1">child-1</div>
    <div class="child-2">child-2</div>
    <div class="child-3">child-3</div>
</div>`}
    </code>
</pre>

            <div className="example-wrapper-column">

<pre>
    <code className="language-css">
{`.parent {
    display: flex;
    justify-content: center;
}

.child-1 {
    align-self: flex-end;
}

.child-2 {
    align-self: center;
}

.child-3 {
    align-self: flex-start;
}`}
    </code>
</pre>

                    <div className="example-wrapper-inner flex15-wrapper">
                        <example-box class="flex15-align-self-1">child-1</example-box>
                        <example-box class="flex15-align-self-2">child-2</example-box>
                        <example-box class="flex15-align-self-3">child-3</example-box>
                </div>
            </div>
            <div className="example-codepen"><a href="https://codepen.io/Grinspoon/pen/bZYqzw" target="_blank" rel="noopener noreferrer">Edit example on codepen</a>
                <i className="fab fa-codepen"></i>
            </div>
        </React.Fragment>
    )
}

export default Example15;