import React from 'react';
import './ExampleCSS1.css';

const ExampleCSS1 = () => {
    return (
<React.Fragment>

<div className="section-text">
There are a few ways to truncate text, the first one is with a fixed width and it's the most common and simplest way of doing it. The downside is that you will not be able to truncate dynamically this way, there is a solution to this problem with flexbox. As you can see in the example below we need a section parent wrapper to center the text so we don't propagate down to the last child.

<br />
<br />

This way of truncating is very depending on how your markup is structured and can be a bit tricky at times to implement in a larger project. The last way of truncating with CSS is by lines, for example only truncate on the third line. I have chosen to exclude this example because it uses an old revision of flexbox (display: -webkit-box;)

<br />
<br />

These examples are best viewed on <a href="https://codepen.io/Grinspoon/pen/BbmROm">codepen</a>
</div>

<pre>
    <code className="language-markup">
{`<!-- fixed width truncate example -->
<div class="parent">
    <div class="child-truncate-fixed">truncate text...</div>
    <div class="child"></div>
</div>`}
    </code>
</pre>

<div className="example-wrapper-column">

<pre>
    <code className="language-css">
{`.parent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #ed8fa9;
}

.child-truncate-fixed {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: initial;
}`}
    </code>
</pre>
                        <div className="css1-wrapper-before">
                            <div className="css1-wrapper-inner">
                                <div className="example-wrapper-inner css1-wrapper">

                                    <div className="css1-truncate-wrapper css1-truncate-width">
                                        <div className="css1-truncate css1-truncate-width">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                                        <example-box></example-box>
                                    </div>
                                </div>


<pre>
    <code className="language-markup">
{`<!-- flex truncate example -->
<div class="parent">
    <div class="child-wrapper">
        <div class="child-truncate-flex">truncate text...</div>
        <div class="child"></div>
    </div>
</div>`}
    </code>
</pre>

<pre>
    <code className="language-css">
{`.parent {
    flex: 1;
    min-width: 0;
}

.child-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #ed8fa9;
}

.child-truncate-flex {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: initial;
}`}
    </code>
</pre>

                                <div className="example-wrapper-inner css1-wrapper">
                                    <div className="css1-truncate-wrapper css1-flex-truncate-width">
                                        <div className="css1-truncate css1-flex-truncate-width">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                                        <example-box></example-box>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="example-codepen"><a href="https://codepen.io/Grinspoon/pen/BbmROm" target="_blank" rel="noopener noreferrer">Edit example on codepen</a>
                        <i className="fab fa-codepen"></i>
                    </div>
                </React.Fragment>
    )
}

export default ExampleCSS1;