import React from 'react';
import './Example12.css';

const Example12 = () => {
    return (
        <React.Fragment>

<div className="section-text">
This flexbox declaration once again is applied on the parent element to modify the children elements inside. Space-between pushes the children evenly (end to end) across, either vertically or horizontally depending on it's flex-direction.
</div>
<pre>
    <code className="language-markup">
{`<!-- space between example -->
<div class="parent">
    <div class="child"></div>
    <div class="child"></div>
    <div class="child"></div>
</div>`}
    </code>
</pre>

            <div className="example-wrapper-column">

<pre>
    <code className="language-css">
{`.parent {
    display: flex;
    align-items: center;
    justify-content: space-between;
}`}
    </code>
</pre>

                <div className="example-wrapper-inner flex12-wrapper">
                    <example-box></example-box>
                    <example-box></example-box>
                    <example-box></example-box>
                </div>
            </div>
            <div className="example-codepen"><a href="https://codepen.io/Grinspoon/pen/qvVrKJ" target="_blank" rel="noopener noreferrer">Edit example on codepen</a>
                <i className="fab fa-codepen"></i>
            </div>
        </React.Fragment>
    )
}

export default Example12;