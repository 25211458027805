import React from 'react';
import './ExampleCSS4.css';

const ExampleCSS4 = () => {
    return (
<React.Fragment>

<div className="section-text">
If you want to modify a specific state of an element you have to use pseudo classes, there are a large variety of them but I will only list the most useful here according to me.

<br />
<br />

<div className="section-text-list">

:hover will modify another state while hovering the element

<br />
<br />

:first-child will target the first element of it's parent

<br />
<br />

:last-child will target the last element of it's parent

<br />
<br />

:first-of-type will target the first element of a specific type of it's parent

<br />
<br />

:last-of-type will target the last element of a specific type of it's parent

<br />
<br />

:nth-child() will target an element by index of it's parent

</div>

</div>

<pre>
    <code className="language-markup">
{`<!-- hover example -->
<div class="parent">
    <div class="child-1">hover me!</div>
</div>`}
    </code>
</pre>

<div className="example-wrapper-column">

<pre>
    <code className="language-css">
{`.child-1:hover {
    color: #fffdf2;
    background-color: #a89cd5; /* purple */
}`}
    </code>
</pre>


                        <div className="example-wrapper-inner css4-wrapper">
                            <example-box class="class-child-1">hover me!</example-box>
                        </div>

                        <pre>
    <code className="language-markup">
{`<!-- first and last-child example -->
<div class="parent">
    <div class="child-2">:first-child</div>
    <div class="child-2">:last-child</div>
</div>`}
    </code>
</pre>

<pre>
    <code className="language-css">
{`.child-2:first-child {
    background-color: #9effc1 /* green */
}

.child-2:last-child {
    background-color: #9eecff; /* blue */
}`}
    </code>
</pre>

                        <div className="example-wrapper-inner css4-wrapper">
                            <example-box class="class-child-2">:first-child</example-box>
                            <example-box class="class-child-2">:last-child</example-box>
                        </div>

                        <pre>
    <code className="language-markup">
{`<!-- first and last-of-type example -->
<div class="parent">
    <p></p>
    <div class="child-3">:first-of-type</div>
    <p></p>
    <div class="child-3">:last-of-type</div>
</div>`}
    </code>
</pre>

<pre>
    <code className="language-css">
{`.child-3:first-of-type {
    background-color: #9effc1 /* green */
}

.child-3:last-of-type {
    background-color: #9eecff; /* blue */
}`}
    </code>
</pre>


                        <div className="example-wrapper-inner css4-wrapper">
                            <p></p>
                            <example-box class="class-child-3">:first-of-type</example-box>
                            <p></p>
                            <example-box class="class-child-3">:last-of-type</example-box>
                        </div>


                        <pre>
    <code className="language-markup">
{`<!-- nth-child() example -->
<div class="parent">
    <div class="child-3">:nth-child(1)</div>
    <div class="child-4">:nth-child(2)</div>
    <div class="child-5">:nth-child(3)</div>
</div>`}
    </code>
</pre>

<pre>
    <code className="language-css">
{`.child-3:nth-child(1) {
    background-color: #9effc1; /* green */
}

.child-3:nth-child(2) {
    background-color: #ff9e9e; /* red */
}

.child-3:nth-child(3) {
    background-color: #9eecff; /* blue */
}`}
    </code>
</pre>

                        <div className="example-wrapper-inner css4-wrapper">
                            <example-box class="class-child-3">:nth-child(1)</example-box>
                            <example-box class="class-child-3">:nth-child(2)</example-box>
                            <example-box class="class-child-3">:nth-child(3)</example-box>
                        </div>
                    </div>

                    <div className="example-codepen"><a href="https://codepen.io/Grinspoon/pen/GexOrq" target="_blank" rel="noopener noreferrer">Edit example on codepen</a>
                        <i className="fab fa-codepen"></i>
                    </div>
                </React.Fragment>
    )
}

export default ExampleCSS4;