import React from 'react';
import './Example16.css';

const Example16 = () => {
    return (
        <React.Fragment>

<div className="section-text">
The default flex direction for child elements inside of a parent element are "row" or horizontal alignment, that means that you don't need to specify it for simpler markup as seen below, if you are making more complex structures and want to override "column" then you can apply it.
</div>

<pre>
    <code className="language-markup">
{`<!-- row example -->
<div class="parent">
    <div class="child">1</div>
    <div class="child">2</div>
    <div class="child">3</div>
</div>`}
    </code>
</pre>

            <div className="example-wrapper-column">

<pre>
    <code className="language-css">
{`.parent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}`}
    </code>
</pre>

                <div className="example-wrapper-inner flex16-wrapper">
                    <example-box>1</example-box>
                    <example-box>2</example-box>
                    <example-box>3</example-box>
                </div>
            </div>
            <div className="example-codepen"><a href="https://codepen.io/Grinspoon/pen/wOPJVo" target="_blank" rel="noopener noreferrer">Edit example on codepen</a>
                <i className="fab fa-codepen"></i>
            </div>
        </React.Fragment>
    )
}

export default Example16;