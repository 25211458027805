import React from 'react';
import './Example20.css';

const Example20 = () => {
    return (
        <React.Fragment>

<div className="section-text">
To have the child elements wrap inside of it's parent element you have to specify on the latter that you are using flex-wrap: wrap; This declaration will only work with container widths that have pixels as in the example below.
</div>

<pre>
    <code className="language-markup">
{`<!-- wrap example -->
<div class="parent">
    <div class="child">1</div>
    <div class="child">2</div>
    <div class="child">3</div>
    <div class="child">4</div>
    <div class="child">5</div>
    <div class="child">6</div>
</div>`}
    </code>
</pre>

            <div className="example-wrapper-column">

<pre>
    <code className="language-css">
{`.parent {
    display: flex;
    flex-wrap: wrap;
    width: 265px;
}`}
    </code>
</pre>

                <div className="example-wrapper-inner flex20-wrapper">
                    <div className="flex20-wrap">
                        <example-box>1</example-box>
                        <example-box>2</example-box>
                        <example-box>3</example-box>
                        <example-box>4</example-box>
                        <example-box>5</example-box>
                        <example-box>6</example-box>
                    </div>
                </div>
            </div>
            <div className="example-codepen"><a href="https://codepen.io/Grinspoon/pen/vPWmyN" target="_blank" rel="noopener noreferrer">Edit example on codepen</a>
                <i className="fab fa-codepen"></i>
            </div>
        </React.Fragment>
    )
}

export default Example20;