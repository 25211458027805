import React from 'react';
import './ExampleCSS2.css';

const ExampleCSS2 = () => {
    return (
<React.Fragment>

<div className="section-text">
Sometimes you want to change the scope of where to apply the CSS, these selectors let's you chain an element and apply it to a target element somewhere in the markup.
<br />
<br />
These examples are best viewed on <a href="https://codepen.io/Grinspoon/pen/rRYbxj">codepen</a>
</div>

<pre>
    <code className="language-markup">
{`<!-- target inside element example -->
<div class="parent-1">
    <div class="target-1">target</div>
</div>`}
    </code>
</pre>

<div className="example-wrapper-column">

<pre>
    <code className="language-css">
{`.parent-1 > .target-1 {
    background-color: #9effc1; /* green */
}`}
    </code>
</pre>
                        <div className="parent-1">
                            <div className="target-1">target</div>
                        </div>

                        <pre>
    <code className="language-markup">
{`<!-- target is after element closing tag example-->
<div class="parent-2">
    <div class="child-2"></div>
    <div class="target-2">target</div>
</div>`}
    </code>
</pre>

<pre>
    <code className="language-css">
{`.child-2 + .target-2 {
    background-color: #ff9e9e; /* red */
}`}
    </code>
</pre>

                        <div className="parent-2">
                        <div className="child-2"></div>
                        <div className="target-2">target</div>
                        </div>

                        <pre>
    <code className="language-markup">
{`<!-- target is somewhere inside element example-->
<div class="parent-3">
    <p></p>
    <div class="target-3">target</div>
    <p></p>
</div>`}
    </code>
</pre>

<pre>
    <code className="language-css">
{`.parent-3 .target-3 {
    background-color: #9eecff; /* blue */
}`}
    </code>
</pre>

                        <div className="parent-3">
                            <p></p>
                            <div className="target-3">target </div>
                            <p></p>
                        </div>

                        <pre>
    <code className="language-markup">
{`<!-- target is sibling of element example-->
<div class="parent-4">
    <div class="child-4"></div>
    <div class="target-4">target</div>
</div>`}
    </code>
</pre>

<pre>
    <code className="language-css">
{`.child-4 ~ .target-4 {
    background-color: #ffc79e; /* orange */
}`}
    </code>
</pre>

                        <div className="parent-4">
                        <div className="child-4"></div>
                            <div className="target-4">target</div>
                        </div>
                    </div>
                    <div className="example-codepen"><a href="https://codepen.io/Grinspoon/pen/rRYbxj" target="_blank" rel="noopener noreferrer">Edit example on codepen</a>
                        <i className="fab fa-codepen"></i>
                    </div>
                </React.Fragment>
    )
}

export default ExampleCSS2;