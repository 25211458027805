import React from 'react';
import './Example14.css';

const Example14 = () => {
    return (
        <React.Fragment>

<div className="section-text">
This is a bit of a niche declaration but it can be very useful in certain scenarios, baseline will keep your text align in the child elements when applied on their parent element. You have to include text to make this declaration work, as you can see in the before and after example down below.
</div>

<pre>
    <code className="language-markup">
{`<!-- baseline example -->
<div class="parent">
    <div class="child">text</div>
    <div class="child">text</div>
    <div class="child">text</div>
    <div class="child">text</div>
</div>`}
    </code>
</pre>

            <div className="example-wrapper-column">

<pre>
    <code className="language-css">
{`.parent {
    display: flex;
    justify-content: center;
    align-items: baseline;
}`}
    </code>
</pre>

                <div className="example-wrapper-inner flex14-wrapper">
                    <example-box class="flex14-baseline-1">text</example-box>
                    <example-box class="flex14-baseline-2">text</example-box>
                    <example-box class="flex14-baseline-3">text</example-box>
                    <example-box class="flex14-baseline-4">text</example-box>
                </div>

<pre>
    <code className="language-css">
{`.parent {
    display: flex;
    justify-content: center;
    align-items: center;
}`}
    </code>
</pre>

                <div className="example-wrapper-inner flex14-wrapper">
                    <example-box class="flex14-baseline-1">text</example-box>
                    <example-box class="flex14-baseline-2">text</example-box>
                    <example-box class="flex14-baseline-3">text</example-box>
                    <example-box class="flex14-baseline-4">text</example-box>
                </div>
            </div>
            <div className="example-codepen"><a href="https://codepen.io/Grinspoon/pen/vPWxVq" target="_blank" rel="noopener noreferrer">Edit example on codepen</a>
                <i className="fab fa-codepen"></i>
            </div>
        </React.Fragment>
    )
}

export default Example14;