import React from 'react';
import './Example1.css';
import Prism from "prismjs";
import "../../../src/prism.css";

class Example1 extends React.Component {

    componentDidMount() {
        Prism.highlightAll();
      }

    render() {

            return (
                <React.Fragment>

<div className="section-text">
To initialise flexbox you have to have declare display: flex; on an element parent, you are then free to use flexbox on child elements inside as in the example below.
<br/><br/>
Here we use the markup below in the first example, it's applied on the parent div to flex the child divs with even space between them. We will go into more detail about these declarations later!
</div>

<pre>
    <code className="language-markup">
{`<!-- flex container example -->
<div class="parent">
    <div class="child"></div>
    <div class="child"></div>
    <div class="child"></div>
</div>`}
    </code>
</pre>

<div className="example-wrapper-column">

<pre>
    <code className="language-css">
{`.parent {
    display: flex;
    justify-content: space-around;
    flex: 1;
}`}
    </code>
</pre>
                        <div className="flex1-wrapper-before">
                            <div className="flex1-wrapper-inner">
                                <example-box></example-box>
                                <example-box></example-box>
                                <example-box></example-box>
                            </div>
                        </div>

<pre>
    <code className="language-markup">
{`<!-- block container example -->
<div class="parent">
    <div class="child"></div>
    <div class="child"></div>
    <div class="child"></div>
</div>`}
    </code>
</pre>

<pre>
    <code className="language-css">
{`.parent {
    display: block;
    justify-content: space-around;
    flex: 1;
}`}
    </code>
</pre>
                        <div className="flex1-wrapper-after">
                            <div className="flex1-wrapper-inner">
                                <example-box></example-box>
                                <example-box></example-box>
                                <example-box></example-box>
                            </div>
                        </div>
                    </div>
                    <div className="example-codepen"><a href="https://codepen.io/Grinspoon/pen/BbmppO" target="_blank" rel="noopener noreferrer">Edit example on codepen</a>
                        <i className="fab fa-codepen"></i>
                    </div>
                </React.Fragment>
            )
    }
}

export default Example1;