import React from 'react';
import ExampleFlex from '../example-flex/Example';
import ExampleCSS from '../example-css/ExampleCSS';
import './Section.css';
import { copyToClipboardMsg } from '../../utilites/Utilites';

const Section = ({data, markupVisible}) => {
    let button = null, copiedContent = null;

    const getElement = () => {
        copyToClipboardMsg(copiedContent);
        button.innerHTML = 'Copied!';

        setTimeout(() => {
            if(typeof (button) != 'undefined' && button != null) {
                button.innerHTML = 'Copy markup';
            }
        }, 1000);
    }

    const getIcon = () => {
        return (
            data.isIntroduction || data.noCopy ?
                (<div className="section-read">
                    <i className="fas fa-book-open"></i>
                </div>) :
                (<div onClick={() => getElement()} className="section-copy">
                    <i className="fas fa-copy"></i>
                    <span className="section-flex" ref={(span) => { copiedContent = span; }}>
                        {data.css}
                    </span>

                    <div className="section-copy-text" ref={(div) => { button = div; }}>Copy markup</div>
                </div>)
        )
    }

    return (
        <div className={`section ${markupVisible ? '' : 'remove-markup'}`}>
            <div className="section-content">
                <div className="section-title">
                    <i className="fas fa-angle-double-right"></i>{data.name}</div>
                    {data.introduction ? <div className="section-text" dangerouslySetInnerHTML={{__html: data.introduction}}></div> : ''}
                { data.isCSSexample ? <ExampleCSS exampleName={data.exampleName} /> :
                    !data.isIntroduction && <ExampleFlex exampleName={data.exampleName} /> }

            </div>
            {getIcon()}
        </div>
    )
}

export default Section;