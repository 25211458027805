import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <a href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
        Made with <i className="fas fa-atom" />
      </a>
      <a
        href="https://fontawesome.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        &nbsp;and&nbsp;
        <i className="fab fa-fort-awesome" />
      </a>
      <span>&nbsp;by Jens Nordström /&nbsp;</span>
      <i className="fas fa-signature" />
      &nbsp;flexbox-manual version 2.4
    </div>
  );
};

export default Footer;
