import React from "react";
import "./Splash.css";

const Splash = ({ theme, spinner }) => {
    return (
        <div className={`${theme} splash`}>
            <div className="splash-inner">
                <div className="splash-logo">
                    <i className="fas fa-signature"></i>
                    flexbox-manual
                </div>

                {spinner ? (
                    <div className="spinnerTwo">
                        <div className="spinnerTwo-init">
                            <div className="shadow"></div>
                            <div className="box"></div>
                        </div>
                    </div>
                ) : (
                    <div className="spinnerOne">
                        <span className="spinnerOne-init"></span>
                    </div>
                )}
            </div>
        </div>
    );
};
export default Splash;
