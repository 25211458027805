import React from 'react';
import './Example13.css';

const Example13 = () => {
    return (
        <React.Fragment>

<div className="section-text">
Compared to space-between, space-around will not push the child elements to the boundaries of it's parent.
</div>

<pre>
    <code className="language-markup">
{`<!-- space around example -->
<div class="parent">
    <div class="child"></div>
    <div class="child"></div>
    <div class="child"></div>
</div>`}
    </code>
</pre>
            
            <div className="example-wrapper-column">

<pre>
    <code className="language-css">
{`.parent {
    display: flex;
    align-items: center;
    justify-content: space-around;
}`}
    </code>
</pre>

                <div className="example-wrapper-inner flex13-wrapper">
                    <example-box></example-box>
                    <example-box></example-box>
                    <example-box></example-box>
                </div>
            </div>
            <div className="example-codepen"><a href="https://codepen.io/Grinspoon/pen/oVoZMm" target="_blank" rel="noopener noreferrer">Edit example on codepen</a>
                <i className="fab fa-codepen"></i>
            </div>
        </React.Fragment>
    )
}

export default Example13;