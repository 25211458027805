import React from 'react';
import './Example17.css';

const Example17 = () => {
    return (
        <React.Fragment>

<div className="section-text">
The revers prefix will make the child elements go in reverse as seen in the text inside of them.
</div>

<pre>
    <code className="language-markup">
{`<!-- row-reverse example -->
<div class="parent">
    <div class="child">1</div>
    <div class="child">2</div>
    <div class="child">3</div>
</div>`}
    </code>
</pre>

            <div className="example-wrapper-column">

<pre>
    <code className="language-css">
{`.parent {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
}`}
    </code>
</pre>

                <div className="example-wrapper-inner flex17-wrapper">
                    <example-box>1</example-box>
                    <example-box>2</example-box>
                    <example-box>3</example-box>
                </div>
            </div>
            <div className="example-codepen"><a href="https://codepen.io/Grinspoon/pen/NJwjWp" target="_blank" rel="noopener noreferrer">Edit example on codepen</a>
                <i className="fab fa-codepen"></i>
            </div>
        </React.Fragment>
    )
}

export default Example17;