import React from 'react';
import './Example21.css';

const Example21 = () => {
    return (
        <React.Fragment>

<div className="section-text">
If you want to reverse the wrap order of the child elements simply use wrap-reverse as the prefix.
</div>

<pre>
    <code className="language-markup">
{`<!-- wrap-reverse example -->
<div class="parent">
    <div class="child">1</div>
    <div class="child">2</div>
    <div class="child">3</div>
    <div class="child">4</div>
    <div class="child">5</div>
    <div class="child">6</div>
</div>`}
    </code>
</pre>

            <div className="example-wrapper-column">

<pre>
    <code className="language-css">
{`.parent {
    display: flex;
    flex-wrap: wrap-reverse;
    width: 265px;
}`}
    </code>
</pre>

                <div className="example-wrapper-inner flex21-wrapper">
                    <div className="flex21-wrap">
                        <example-box>1</example-box>
                        <example-box>2</example-box>
                        <example-box>3</example-box>
                        <example-box>4</example-box>
                        <example-box>5</example-box>
                        <example-box>6</example-box>
                    </div>
                </div>
            </div>
            <div className="example-codepen"><a href="https://codepen.io/Grinspoon/pen/GeOmyr" target="_blank" rel="noopener noreferrer">Edit example on codepen</a>
                <i className="fab fa-codepen"></i>
            </div>
        </React.Fragment>
    )
}

export default Example21;