import React from "react";
import "./HomePage.css";
import { reactLocalStorage } from "reactjs-localstorage";
import { HashLink as Link } from "react-router-hash-link";
import Menu from "../../components/menu/Menu";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Splash from "../../components/splash/Splash";
import Section from "../../components/section/Section";
import { flexbox } from "../../sections-flex";
import { css } from "../../sections-css";

class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.spinnerOne = reactLocalStorage.getObject("spinner") === true;
        reactLocalStorage.set("spinner", !this.spinnerOne);

        this.state = {
            loading: true,
            darkTheme: reactLocalStorage.getObject("theme") === true,
            markupVisible: reactLocalStorage.getObject("markup") !== false
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loading: false
            });
        }, 2200);

        setTimeout(() => {
            document.querySelector(".splash").classList.add("splash-fade-out");
        }, 1600);

        document.addEventListener("scroll", this.handleScroll, true);
    }

    handleScroll = () => {
        const getScrollElement = document.querySelector(".section-spacer-top");
        const getScrollTopElement = document.querySelector(".scroll-top");

        if (
            typeof getScrollElement != "undefined" &&
            getScrollElement != null
        ) {
            if (getScrollElement.getBoundingClientRect().top < -175) {
                getScrollTopElement.classList.add("scroll-top-show");
                getScrollTopElement.classList.remove("scroll-top-hide");
            } else {
                getScrollTopElement.classList.add("scroll-top-hide");
                getScrollTopElement.classList.remove("scroll-top-show");
            }
        }
    };

    markupToggle = () => {
        const newState = !this.state.markupVisible;

        this.setState({
            markupVisible: newState
        });

        reactLocalStorage.set("markup", newState);
    };

    darkTheme() {
        const newState = !this.state.darkTheme;

        this.setState({
            darkTheme: newState
        });

        reactLocalStorage.set("theme", newState);

        //window.location.reload();
    }

    render() {
        const { loading, darkTheme, markupVisible } = this.state;
        const themeClassSpinner = darkTheme ? "dark" : "light";
        const themeClassContent = darkTheme ? "dark" : "light";

        if (loading) {
            return (
                <Splash theme={themeClassSpinner} spinner={this.spinnerOne} />
            );
        }

        return (
            <React.Fragment>
                <div className={`${themeClassContent} theme`}>
                    <Header onClick={this.darkTheme.bind(this)} />
                    <div className="content">
                        <Menu
                            onClick={this.darkTheme.bind(this)}
                            theme={darkTheme ? "on" : "off"}
                            markupToggle={this.markupToggle}
                            markupVisible={markupVisible ? "on" : "off"}
                        />
                        <div className="sections">
                            <div className="sections-list">
                                <div>
                                    {flexbox.map((section, keyValue) => {
                                        return (
                                            <div id={keyValue} key={keyValue}>
                                                <div
                                                    className={`${
                                                        keyValue === 0
                                                            ? "section-spacer-top"
                                                            : "section-spacer"
                                                    }`}
                                                ></div>
                                                <Section
                                                    data={section}
                                                    markupVisible={
                                                        markupVisible
                                                    }
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                                <div>
                                    {css.map((section, keyValue) => {
                                        return (
                                            <div
                                                id={keyValue + flexbox.length}
                                                key={keyValue}
                                            >
                                                <div
                                                    className={`${
                                                        keyValue === 0
                                                            ? "section-spacer-top"
                                                            : "section-spacer"
                                                    }`}
                                                ></div>
                                                <Section
                                                    data={section}
                                                    markupVisible={
                                                        markupVisible
                                                    }
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                                <Footer />
                            </div>
                        </div>
                        <Link
                            className="scroll-top scroll-top-hide"
                            to="#0"
                            scroll={el =>
                                el.scrollIntoView({
                                    behavior: "auto",
                                    block: "start"
                                })
                            }
                        >
                            <i className="fas fa-arrow-circle-up"></i>
                        </Link>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default HomePage;
