import React from "react";
import "./Header.css";

const Header = ({ onClick }) => {
    return (
        <div className="header">
            <div>
                <i className="fas fa-signature"></i>
                flexbox-manual
            </div>
            <div className="header-theme" onClick={onClick}>
                <i className="fas fa-adjust"></i>
            </div>

            <div className="paypal-button">
                Buy me a coffee <i className="fab fa-paypal"></i>
            </div>
            <form
                className="paypal-real"
                action="https://www.paypal.com/cgi-bin/webscr"
                method="post"
                target="_top"
            >
                <input type="hidden" name="cmd" value="_donations" />
                <input type="hidden" name="business" value="3MXKQCKHEGE6C" />
                <input type="hidden" name="currency_code" value="SEK" />
                <input
                    className="paypal-img"
                    type="image"
                    src="https://i.imgur.com/zHgdSvY.gif"
                    border="0"
                    name="submit"
                    alt=""
                />
                <img
                    alt=""
                    border="0"
                    src="https://www.paypal.com/en_SE/i/scr/pixel.gif"
                    width="1"
                    height="1"
                />
            </form>
        </div>
    );
};

export default Header;
